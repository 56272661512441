import {useCallback, useEffect, useState} from "react";
import {getToken} from "firebase/messaging";
import "react-loading-skeleton/dist/skeleton.css";
import InvestmentPortfolioAndWallet from "./components/InvestmentPortfolioAndWallet/InvestmentPortfolioAndWallet";
import PortfolioItem2 from "./components/PortfolioItem2/PortfolioItem2";
import "./page.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import FullModal from "../../components/FullModal/FullModal";
import {getBalance, UserBalance} from "../../api/users";
import RouteCards from "./components/RoutesCard/RouteCard";
import Profile from "../../components/Profile/Profile";
import {messaging} from "../../Firebase";
import {useNavigate} from "react-router";

export default function Home() {
  const [user, setUser] = useState<UserBalance | undefined>();
  const [showAccount, setShowAccount] = useState<boolean>(false);
  const navigate = useNavigate();

  const loadUser = useCallback(async () => {
    const user = await getBalance();
    if (!user || !('id' in user)) {
      navigate("/login", {replace: true});
      return;
    }

    try {
      const userId = user.id; // Replace your_user_id with your own if available.
      // @ts-ignore
      window.hj('identify', userId, {});
    } catch (e) {
      console.error("Error identifying user in Hotjar: ", e);
    }

    setUser(user);
  }, []);

  useEffect(() => {
    loadUser();
    requestPermission();
  }, []);

  function requestPermission() {
    try {
      if (typeof Notification !== "undefined") {
        if (Notification.permission === "default") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              // console.log("Notification permission granted.");
            }
          });
        } else {
          // console.log("Notification permission already granted or denied.");

          if (Notification.permission === "granted" && messaging) {
            getToken(messaging, {
              vapidKey:
                "BBMc1JoG3lwvLIacuItGDfvuibuGjkJW4-nvdIA_i-rT1SXhsRkaO_LIJgK1YP2bhY89TaiuHT_K7iTVYAnvp7k",
            }).then((r) => {
              console.debug("Token: ", r);
            });
          }
        }
      }
    } catch (e) {
      console.error("Error requestPermission: ", e);
    }
  }

  return (
    <>
      <div className="home-page-main">
        <div className="home-page-user-header">
          {user ? <h1>Olá, {user?.name}</h1> : ""}
          <img className={"companyLogo"} src={"/tractian.jpg"} width={"40px"} height={"40px"}/>
          <button onClick={() => setShowAccount(true)}>
            <FontAwesomeIcon icon={faUser} size="sm"/>
          </button>
        </div>
        <div className="home-page-content">
          <section className="home-page-content-cards">
            <InvestmentPortfolioAndWallet user={user}/>
          </section>

          <section className="cards-section">
            <RouteCards/>
          </section>

          <section className="doacoes-section">
            <h4>Doações</h4>
            {user && user.investments?.length > 0 ? (
              user.investments.map((investment: any) => {
                console.log("investments", investment);
                return (
                  <PortfolioItem2 key={investment.id} investment={investment}/>
                )
              })
            ) : (
              <p style={{marginTop: '1rem', fontSize: '0.9rem', color: '#9f9f9f'}}>Suas doações vão aparecer aqui.</p>
            )}
          </section>
        </div>
      </div>

      {showAccount && (
        <FullModal closeModal={() => setShowAccount(false)}>
          <Profile/>
        </FullModal>
      )}

    </>
  );
}
