import { Performance } from "./performance";
import { request } from "./req";

export type Category = {
  id: string;
  name: string;
};

export type Image = {
  url: string;
  position: 1 | 2 | 3 | 4 | 5;
};

export type Organization = {
  id: string;
  name: string;
  description: string;
  location: string;
  logo: string;
  link?: string;
  social?: string;
  headline?: string;
  category?: Category;
  images?: Image[];
  presentation?: string;
};

export async function listOrganizations() {
  return await request("get", "organizations");
}

export async function getOrganizationById(id: string) {
  return await request("get", `organizations/${id}`);
}
