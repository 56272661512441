import {useState} from "react";
import "./PortfolioItem2.scss";
import {Organization} from "../../../../api/organization";
import FullModal from "../../../../components/FullModal/FullModal";
import OrgDetails from "../../../orgs/components/OrgDetails/OrgDetails";
import {toReal} from "../../../../helpers/formatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";
import ProjectDetails from "../../../projects/components/ProjectDetails";
import {Transaction} from "../../../../api/transaction";

type PortfolioItemProps = {
  key: string
  investment: Transaction;
};

const getChartColor = (chartData: number): InterfaceColors => {
  return chartData > 0 ? "#4caf50" : "#324d8d";
};

const buildColors = (investment: Transaction): [number, InterfaceColors] => {
  const performance = investment.performance;
  const chartData = performance?.value || 0.0;
  const chartColor = getChartColor(chartData);

  console.log("inside buildColors", {chartData, chartColor});

  return [chartData, chartColor];
};

export default function PortfolioItem2(props: PortfolioItemProps) {
  const {investment} = props;
  const {organization, project} = investment;
  console.log("inside PortfolioItem2");
  console.log("organization", organization);
  console.log();

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  if (!organization) return <></>;

  const [chartData, chartColor] = buildColors(investment);

  return (
    <div className="portfolio-item" key={props.key}>
      <div
        className="portfolio-item-org-description"
        onClick={() => setSelectedOrg(organization)}
      >
        <img src={organization.logo}/>
        <div>
          {
            investment.project ? (
              <h4>{investment.project.name}</h4>
            ) : <></>
          }
          <h3>{organization.name}</h3>
        </div>
      </div>

      <div>
        <h3 className="portfolio-item-org-amount">
          R$ {toReal(investment.amount)}
        </h3>
        {(
          <h4
            style={{color: chartColor}}
            className="portfolio-item-org-percentage"
            data-tooltip-id="invest-tooltip"
            data-tooltip-html="<div>Retorno social do seu investimento. Atualizado semanalmente com base nos principais indicadores da ONG.</div>"
          >
            <span>{chartData?.toFixed(2)}%</span>

            <span className="portfolio-item-org-percentage-info">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="orange"
                size="lg"
              />
            </span>
          </h4>
        )}

        <Tooltip id="invest-tooltip"/>
      </div>

      {selectedOrg && (
        <FullModal closeModal={() => setSelectedOrg(null)}>
          {project ? (
            <ProjectDetails project={project}/>
          ) : (
            <OrgDetails organization={selectedOrg}/>
          )}
        </FullModal>
      )}
    </div>
  );
}

type InterfaceColors = "#4caf50" | "#f44336" | "#324d8d";
