import React, {useCallback, useEffect, useState} from "react";
import "./DonateForm.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import {User, getBalance, UserBalance} from "../../../../api/users";
import {invest} from "../../../../api/transaction";
import FullModal from "../../../../components/FullModal/FullModal";
import BalanceCard from "../../../../components/BalanceCard/BalanceCard";
import IntlCurrencyInput from "../../../../components/CurrencyInput/CurrencyInput";
import {Organization} from "../../../../api/organization";
import {toReal} from "../../../../helpers/formatters";
import {Project} from "../../../../api/projects";


interface DonateProps {
  organization: Organization;
  project?: Project;
  onDoneDonating: () => void;
  onClose: () => void;
}


export default function DonateForm(props: DonateProps) {
  const {organization, project} = props;

  console.log("{organization, project}", {organization, project})

  const [amount, setAmount] = useState<number>(0);
  const [user, setUser] = useState<UserBalance>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isLoadingDonation, setIsLoadingDonation] = useState<boolean>(false);
  const [hasDonated, setHasDonated] = useState<boolean>(false);

  const loadUser = useCallback(async () => {
    const user = await getBalance();

    setUser(user);
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  const goBackToHome = useCallback(() => {
    window.location.reload();
  }, []);

  const onSubmit = useCallback(async () => {
    if (isLoadingDonation) return;

    try {
      setIsLoadingDonation(true);

      if (project) {
        await invest(organization?.id, project?.id, amount);
      } else {
        await invest(organization?.id, null, amount);
      }

      setHasDonated(true);
      setIsLoadingDonation(false);
    } catch (err) {
      console.error("ERRO: ", err);
      alert("Ocorreu um erro ao fazer doação")
    } finally {
    }
  }, [amount]);

  if (!organization) return <></>;

  if (hasDonated) {
    return (
      <FullModal className="congratulations-modal">
        <div className="congratulations-donate">
          <h2>Muito obrigado pela sua doação!</h2>

          <div className="congratulations-donate-check">
            <FontAwesomeIcon icon={faCheck} size="4x"/>
          </div>

          <span className="congratulations-donate-amount">
            R$ {toReal(amount)}
          </span>

          <span className="congratulations-donate-success-message">
            Sua doação foi recebida com sucesso!
          </span>

          <button
            className="congratulations-donate-back-button"
            onClick={goBackToHome}
          >
            Voltar para tela inicial
          </button>
        </div>
      </FullModal>
    );
  }

  return (
    <div className="donate-form">
      <BalanceCard balance={user?.balance}/>
      <h2>Qual valor você gostaria de doar?</h2>

      <div className="donate-form-input">
        <IntlCurrencyInput
          currency="BRL"
          onChange={(event: any, value: any, maskedValue: any) => {
            console.log(value); // value without mask (ex: 1234.56)
            console.log(maskedValue); // masked value (ex: R$1234,56)

            setAmount(value);
          }}
          defaultValue={0}
          max={1000000000}
          value={amount}
        />
      </div>
      {project ? (
        <div>
          <div className="donate-form-project-headline">
            <span className="donate-form-project-headline-title">Projeto: {project.name}</span>
            <span className="donate-form-project-headline-info">
              {project.description}
            </span>
          </div>

          <div className="donate-form-org-headline">
            <span className="donate-form-org-headline-title">Sobre a ONG</span>
            <span className="donate-form-org-headline-info">
              {organization.headline}
            </span>
          </div>
        </div>
      ) : <></>}

      {!isConfirming ? (
        <button
          disabled={amount < 1 || (user?.balance || 0) < amount}
          className="next-button donate-form-next-button"
          onClick={() => setIsConfirming(true)}
        >
          <FontAwesomeIcon icon={faCheck} size="xl"/>
        </button>
      ) : (
        <>
          <button onClick={onSubmit} className="donate-form-confirm-button">
            {!isLoadingDonation ? (
              "Confirmar doação"
            ) : (
              <ReactLoading
                width={20}
                height={20}
                type="spinningBubbles"
                color="#6bd666"
              />
            )}
          </button>
        </>
      )}
    </div>
  );
}