import React from 'react';
import './faq.scss';
import {Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

function Faq() {
  return (
    <Box mt={4} mb={4} className={"faq"}>
      <Box mt={1} mb={1}>
        <h3>01. Como o app funciona?</h3>
        <p>A giv é uma plataforma que facilita sua doação, onde cada colaborador das empresas parceiras têm acesso a um
          ambiente exclusivo. Nesse espaço, você pode visualizar ONGs selecionadas pela sua empresa e usar um valor
          disponibilizado por ela para fazer doações. O app também oferece um histórico das suas doações e informações
          detalhadas sobre as ONGs.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>02. É dinheiro meu ou da empresa?</h3>
        <p>O saldo disponível no app é fornecido pela sua empresa como parte de uma iniciativa de impacto social e
          engajamento interno. Você utiliza esse saldo para fazer doações às ONGs que você escolher.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>03. Posso doar dinheiro meu também?</h3>
        <p>No momento, a giv não permite doações com recursos próprios. O foco é utilizar o saldo disponibilizado pela
          empresa para apoiar ONGs previamente selecionadas.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>04. Eu preciso fazer um PIX para a ONG?</h3>
        <p>Não, todo o processo de doação é feito dentro do app. Sua empresa gerencia o pagamento diretamente às ONGs
          com base nas doações que você realiza no app.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>05. Posso sugerir uma ONG?</h3>
        <p>Sim, você pode sugerir uma ONG diretamente para o responsável pelo programa na sua empresa. Essa sugestão
          será analisada internamente, e a ONG pode ser incluída em futuras campanhas ou rodadas de doação.</p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>06. O que acontece depois que eu faço uma doação?</h3>
        <p>Sua doação é registrada no histórico do app e a ONG recebe a quantidade doada. Você também pode acompanhar os
          impactos gerados pelas doações com dados fornecidos pela giv.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>07. Como as ONGs são selecionadas?</h3>
        <p>As ONGs no app são escolhidas pela empresa, em parceria com a equipe da giv, com base em critérios como
          impacto social, transparência e alinhamento com os valores da empresa.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>08. Como posso acompanhar o impacto das minhas doações?</h3>
        <p>No app, você terá acesso a atualizações de impacto, que mostrarão a evolução das ONGs apoiadas com base nas
          suas primeiras decisões, com dados como projetos financiados, pessoas impactadas e outras métricas
          específicas.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>09. Posso mudar minha doação depois de feita?</h3>
        <p>Não, as doações realizadas são finais e não podem ser alteradas. Se você tiver dúvidas ou precisar de
          suporte, entre em contato com o suporte do app ou a equipe da sua empresa.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>10. Meus dados estão seguros no app?</h3>
        <p>Sim, a giv utiliza os melhores protocolos de segurança e criptografia para proteger seus dados. Apenas
          informações básicas como nome, número de telefone e histórico de doações são armazenadas para garantir sua
          experiência no app.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>11. Como faço login no app?</h3>
        <p>O login é feito utilizando seu número de telefone. Se ele estiver cadastrado no banco de dados, você receberá
          uma SMS com o código de acesso, sem necessidade de e-mail ou senha.
        </p>
      </Box>
      <Box mt={1} mb={1}>
        <h3>12. E se eu não encontrar a ONG que quero apoiar?</h3>
        <p>Você pode sugerir uma ONG para o responsável pelo programa na sua empresa. Caso a sugestão seja aceita, ela
          poderá ser incluída em campanhas futuras. Mas temos certeza que você vai se surpreender com os projetos
          incríveis que ajudam milhares de pessoas.</p>
      </Box>
      <Box mt={4} mb={10}>
        <p>Qualquer dúvida ou sugestão, fique à vontade para entrar em contato com nosso time.</p>
        <br/>
        <a href={"https://api.whatsapp.com/send?phone=+5511996239127&text=Oi,+vim+do+app+giv!"} target={"_blank"}
           style={{textDecoration: 'none', color: '#56BC6C'}}>
          <FontAwesomeIcon icon={faWhatsapp} size={"xl"}/> <strong>Clique aqui para falar com o time giv</strong>
        </a>
        <br/>
        <br/>
        <a href={"mailto:contato@givbr.com"} style={{textDecoration: 'none', color: '#0a4a7e'}}>
          <FontAwesomeIcon icon={faEnvelope} size={"xl"}/> <strong>contato@givbr.com</strong>
        </a>
      </Box>
      <br/>
    </Box>
  );
}

export default Faq;