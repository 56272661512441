import React, {useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {Box, Grid} from "@mui/material";
import styles from "../../../page.module.css";
import {
  Organization,
  getOrganizationById,
} from "../../../../api/organization";
import Centered from "../../../../components/Centered";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate, faCheck} from "@fortawesome/free-solid-svg-icons";

export function TransparencyContent({organizationId}: any) {
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );

  useEffect(() => {
    getOrganizationById(organizationId).then((organization) => {
      setOrganization(organization);
    });
  }, [organizationId]);

  if (!organization) {
    return (
      <Centered>
        <ReactLoading type="spinningBubbles" color="#6bd666"/>
      </Centered>
    );
  }

  console.log(organization.presentation)
  return (
    <>
      <Box mt={2} mb={6}>
        <p>Com a giv você faz uma doação segura e transparente.</p>
        <p>Todas as organizações sociais parceiras são verificadas e acompanhadas de maneira contínua.</p>
      </Box>
      <Grid
        container
        spacing={0}
        justifyContent={"left"}
        alignItems={"left"}
      >
        <Grid item>
          <div className={styles.bigLogoContainer}>
            <img
              className="transparency-logo"
              src={organization.logo}
              alt={organization.name}
            />
          </div>
        </Grid>
        <Grid item p={1}>
          <h2 className={styles.title}>{organization.name}</h2>
        </Grid>
      </Grid>
      <Box mt={4} mb={4}>
        <div
          dangerouslySetInnerHTML={{
            __html: organization.presentation?.replace(/\n/g, "<br/>") || "",
          }}
        />
        <br/>
        <small>*Certidão Negativa de Débito, Reclame Aqui Confiável, Estatuto, Ata de Eleição e Conselho Fiscal
          atualizado (05/01/2025).</small>
      </Box>
      <Box mt={2} mb={4}>
        <span>
          <FontAwesomeIcon color="#74c0fc" icon={faCertificate} size={"lg"}/>
          <FontAwesomeIcon color="#fff" icon={faCheck} size={"xs"} style={{position: 'absolute', left: '26px', marginTop: '4px'}}/>
          <b style={{marginLeft: '5px'}}>GIV | Verificada</b>
        </span>
      </Box>
      {/*<hr/>*/}
      {/*<Box mt={4} mb={4}>*/}
      {/*  <p>Qualquer dúvida ou sugestão, fique à vontade para entrar em contato com nosso time.</p>*/}
      {/*  <br/>*/}
      {/*  <a href={"https://api.whatsapp.com/send?phone=5511996239127"} target={"_blank"}>*/}
      {/*    <FontAwesomeIcon icon={faWhatsapp}/> Clique aqui para falar com o time giv*/}
      {/*  </a>*/}
      {/*</Box>*/}
    </>
  );
}
