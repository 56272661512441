import React, {useEffect, useState} from "react";
import "./OrgDetails.scss";
import Skeleton from "react-loading-skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {TransparencyContent} from "../../[id]/transparencia/content";
import FullModal from "../../../../components/FullModal/FullModal";
import {
  Organization,
  getOrganizationById,
} from "../../../../api/organization";
import DonateForm from "../DonateForm/DonateForm";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

export default function OrgDetails(props: OrgDetailsItemProps) {
  const {organization: propOrg} = props;
  console.log(propOrg);

  const [organization, setOrganization] = useState<Organization>();
  const [isDonating, setIsDonating] = useState<boolean>(false);
  const [showTransparency, setShowTransparency] = useState<boolean>(false);

  useEffect(() => {
    getOrganizationById(propOrg.id).then((organization) => {
      console.log(organization);
      setOrganization(organization);
    });
  }, [propOrg]);

  console.log(organization?.presentation);

  return (
    <div className="organization-details">
      <div className="organization-details-header">
        <div className="organization-details-header-main">
          {organization ? (
            <img
              src={organization.logo}
              className="organization-details-logo"
            />
          ) : (
            <Skeleton width={60} height={60}/>
          )}
          <div className="organization-details-header-info">
            <span className="organization-details-name">
              {organization ? organization.name : <Skeleton width={100}/>}
            </span>
            {organization ? (
              <span className="org-category-badge">
                {organization.category?.name}
            </span>
            ) : (
              <Skeleton width={100}/>
            )}
          </div>
        </div>
        <div className="organization-details-header-contact">
          {organization ? (
            <a href={organization.social} target="_blank">
              <FontAwesomeIcon icon={faInstagram}/>
              <span>Instagram</span>
            </a>
          ) : (
            <Skeleton width={130}/>
          )}

          {organization ? (
            <a href={organization.link} target="_blank">
              <FontAwesomeIcon icon={faExternalLink}/>
              <span>Site</span>
            </a>
          ) : (
            <Skeleton/>
          )}

          {organization ? (
            <a onClick={() => setShowTransparency(true)}>
              <FontAwesomeIcon icon={faInfoCircle}/>
              <span>Saiba mais</span>
            </a>
          ) : (
            <Skeleton/>
          )}
        </div>
      </div>
      {organization ? (
        <span className="organization-details-headline">
          {organization.headline}
        </span>
      ) : (
        <Skeleton height={70}/>
      )}

      <div className="organization-details-image-list">
        {organization ? (
          organization.images?.map((img) => <img key={img.url} src={img.url}/>)
        ) : (
          <Skeleton
            containerClassName="skeleton-images-list"
            count={3}
            width="33%"
            height={170}
          />
        )}
      </div>

      {
        organization ? (
          <span className="organization-details-description"
                dangerouslySetInnerHTML={{
                  __html: organization.description?.replace(/\n/g, "<br/>") || "",
                }}/>
        ) : (
          <Skeleton height={70}/>
        )
      }

      <div className="organization-details-invest">
        <button disabled={!organization} onClick={() => setIsDonating(true)}>
          Quero doar
        </button>
      </div>

      {isDonating && organization && (
        <FullModal
          order={{
            id: "donate-form",
            sequence: 2,
          }}
          closeModal={() => setIsDonating(false)}
        >
          <DonateForm
            organization={organization}
            onClose={() => setIsDonating(false)}
            onDoneDonating={() => setIsDonating(false)}
          />
        </FullModal>
      )}

      {organization && showTransparency && (
        <FullModal closeModal={() => setShowTransparency(false)}>
          <TransparencyContent organizationId={organization.id}/>
        </FullModal>
      )}
    </div>
  );
}

interface OrgDetailsItemProps {
  organization: Organization;
}
