import {BrowserRouter, Routes, Route} from "react-router-dom";
import Helmet from "react-helmet";
import Home from "./app/home/page";
import Login from "./app/login/page";
import Signup from "./app/signup/page";
import {useEffect, useState} from "react";
import {User} from "./api/users";
import {getUser} from "./app/storage";

import "./app/globals.css";
import "./App.css";

export default function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const localUser = getUser();

    setUser(localUser);
  }, []);

  if (user === null) {
    return <div className="App"></div>;
  }

  return (
    <div className="App">
      <Helmet>
        <title>GIV - app de doação</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login">
            <Route index element={<Login/>}/>
          </Route>
          <Route path="/signup">
            <Route index element={<Signup/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
