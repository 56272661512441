import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import "./Profile.scss";
import Skeleton from "react-loading-skeleton";
import {getBalance, UserBalance} from "../../api/users";
import {removeUser} from "../../app/storage";
import {useNavigate} from "react-router";
import IMask from "imask";
import MaskedTextField from "../MaskedTextField";


export default function Profile(props: any) {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [user, setUser] = useState<UserBalance | undefined>(undefined);
  const [cpf, setCpf] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const logout = () => {
    removeUser();
    navigate("/login", {replace: true});
  };

  useEffect(() => {
    getBalance().then((userBalance) => {
      setUser(userBalance);

      setPhone(userBalance.phone.replace("+55 ", ""))
      setCpf(userBalance.cpf)
    });
  }, []);

  const cpfMasks = [{mask: "000.000.000-99"}];
  const phoneMasks = [{mask: "(00) 00000-0000"}];

  return (
    <div className="user-profile">
      <div className="user-profile-title">
        <div className="user-profile-icon">
          <FontAwesomeIcon icon={faUser} color="white"/>
        </div>
        <div className="user-profile-info">
          <h2 className="user-profile-name">
            {user ? user.name : <Skeleton/>}
          </h2>
        </div>
      </div>

      <div className="user-profile-description">
        <span className="user-profile-description-data">
          <span className="user-profile-description-data-title">Telefone</span>

          {user ? (
            <MaskedTextField ref={inputRef} value={phone} mask={phoneMasks} disabled onClick={() => false} />
          ) : (
            <Skeleton style={{marginBottom: 5}} width={170} height={20}/>
          )}
        </span>

        <span className="user-profile-description-data" style={{display: 'none'}}>
          <span className="user-profile-description-data-title">CPF</span>
          {user ? (
            <MaskedTextField ref={inputRef} value={cpf} mask={cpfMasks} disabled onClick={() => false}/>
          ) : (
            <Skeleton style={{marginBottom: 5}} width={170} height={20}/>
          )}
        </span>
      </div>

      <div className="logout-button-container">
        <button onClick={() => logout()}>DESCONECTAR</button>
      </div>
    </div>
  );
}
