import React, {useState} from "react";
import styles from "../../components/auth.module.css";
import {getBalance} from "../../api/users";
import {Container} from "../../components/Container";
import {CAPTCHA_ID} from "../base";
import Auth from "../../components/Auth";
import {useNavigate} from "react-router";

export default function Login() {
  const [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(true);

  const navigate = useNavigate();
  const successCallback = async () => {
    try {
      getBalance().then((balance) => {
        console.log("typeof balance", typeof balance);
        if ('id' in balance) {
          navigate("/", {replace: true});
        } else {
          alert("Erro ao acessar, verifique se seu número foi adicionado pela empresa ou tente novamente.");
          window.location.reload()
        }
      });
    } catch (e) {
      alert("Erro ao acessar, verifique se seu número foi adicionado pela empresa ou tente novamente.");
      window.location.reload()
    }
  };

  return (
    <main className={styles.main}>
      <Container fullHeight={true}>
        <h2 className={styles.title}>Entrar</h2>
        <h3 className={styles.description}>
          Insira o seu telefone para acessar
        </h3>
        {recaptchaEnabled && (
          <div
            id={CAPTCHA_ID}
            style={{height: "78px", margin: "0 auto"}}
          />
        )}
        <Auth
          withEmail={false}
          successCallback={successCallback}
          captchaId={CAPTCHA_ID}
          setRecaptchaEnabled={setRecaptchaEnabled}
        />
      </Container>
    </main>
  );
}
